import Api from "../apis/Api";

export default {
  data: function () {
    return {
      phpmyadminSsoLoading: false,
      phpmyadminSsoUrl: "",
    };
  },
  methods: {
    phpmyadminSsoLogin(hostingAccountId) {
      if (this.phpmyadminSsoLoading) {
        return;
      }
      this.phpmyadminSsoLoading = true;
      Api.post(`/server-accounts/${hostingAccountId}/mysql/phpmyadmin-sso-url`).then((response) => {
        window.open(response.data.data.url);
      }).catch((error) => {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.phpmyadminSsoLoading = false;
      });
    },
  },
}
